<template>
  <b-card class="h-100" no-body>
    <b-card-header id="CaseTemplateFormHeader">
      <b-row class="mb-3">
        <b-col>
          <h3>{{ $t("views.caseTemplateList.screeTitle") }}</h3>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-form-checkbox v-model="Enable" name="check-button" switch>{{
            $t("views.caseTemplateList.ShowEnableCaseTemplates")
          }}</b-form-checkbox>
        </b-col>
        <b-col>
          <b-button-toolbar>
            <b-button-group class="ml-auto">
              <b-input
                v-model="keyword"
                size="sm"
                :placeholder="$t('general.search')"
                id="CaseTemplateSearchInput"
              />
              <b-button
                v-if="keyword == ''"
                variant="primary"
                v-b-tooltip.hover
                size="sm"
                :title="$t('general.search')"
                id="CaseTemplateSearchButton"
                ><b-icon-search />
              </b-button>
              <b-button
                v-else
                variant="primary"
                v-b-tooltip.hover
                size="sm"
                :title="$t('general.search')"
                @click="keyword = ''"
              >
                <b-icon-x />
              </b-button>
            </b-button-group>
            <b-button-group class="mx-1">
              <b-button
                v-show="Enable"
                variant="primary"
                v-b-tooltip.hover
                size="sm"
                :title="$t('views.caseTemplateList.AddCaseTemplate')"
                @click="showAddCaseTemplate = !showAddCaseTemplate"
                v-b-modal.modal-new-case
              >
                <b-icon-plus />
              </b-button>
              <b-button
                v-show="Enable"
                variant="primary"
                v-b-tooltip.hover
                size="sm"
                :title="$t('views.caseTemplateList.EditCaseTemplate')"
                :disabled="isSelected || !selected[0].canEdit"
                @click="EditCaseTemplate"
                id="CaseTemplateEditCaseTemplate"
              >
                <b-icon-pencil />
              </b-button>
              <b-button
                v-show="Enable"
                variant="primary"
                v-b-tooltip.hover
                size="sm"
                :title="$t('views.caseTemplateList.removeCaseTemplate')"
                :disabled="isSelected"
                @click="RemoveCaseTemplate"
              >
                <b-icon-trash />
              </b-button>
              <b-button
                v-show="!Enable"
                variant="primary"
                v-b-tooltip.hover
                size="sm"
                :title="$t('views.caseTemplateList.activate')"
                :disabled="isSelected"
                @click="ActiveCaseTemplate"
              >
                <b-icon-check />
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="overflow-auto p-0">
      <b-table
        :busy="busy"
        :fields="fields"
        :items="caseTemplatesbyOU"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="keyword"
        :selectable="selectable"
        small
        outlined
        select-mode="single"
        @row-selected="onRowSelected"
        @filtered="filtered"
        id="CaseTemplateList"
        ><template v-slot:table-busy> <simple-loader /> </template></b-table
    ></b-card-body>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      pills
      size="sm"
      align="center"
    ></b-pagination>
    <!-- Modal to create a new caseTemplate -->
    <b-modal
      id="modal-new-case"
      :title="$t('views.caseTemplateList.createANewCaseTemplate')"
      @ok="handleOk"
      @cancel="handleCancel"
      :cancel-disabled="busy"
      :ok-disabled="busy"
      size="lg"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row v-if="busy">
          <b-col class="text-center">
            <simple-loader />
          </b-col>
        </b-row>
        <div v-else>
          <b-form-group
            :label="$t('views.caseTemplateList.newTemplateName')"
            label-for="name-input"
            :invalid-feedback="$t('views.caseTemplateList.nameRequired')"
            label-cols="4"
            :state="formState.caseTemplateName"
          >
            <b-form-input
              id="name-input"
              required
              v-model="formNewCase.caseTemplateName"
              :state="formState.caseTemplateName"
              :placeholder="$t('views.caseTemplateList.name')"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :label="$t('views.caseTemplateList.OU')"
            label-for="organizational-unit-select"
            :invalid-feedback="$t('views.caseTemplateList.OURequired')"
            label-cols="4"
            :state="formState.idOrganizationalUnit"
          >
            <b-form-select
              v-model="formNewCase.idOrganizationalUnit"
              :options="options"
              text-field="nameOrganizationalUnit"
              value-field="idOU"
              required
              :state="formState.idOrganizationalUnit"
              @row-selected="onRowSelected"
            >
              >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("views.caseTemplateList.OUInstructions")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
      </form>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button @click="handleCancel" :disabled="busy">{{
            $t("general.cancel")
          }}</b-button
          ><b-button variant="primary" @click="handleOk" :disabled="busy">{{
            $t("general.ok")
          }}</b-button></slot
        >
      </template>
    </b-modal>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      Enable: true,
      perPage: 8,
      currentPage: 1,
      busy: false,
      keyword: "",
      showAddCaseTemplate: false,
      selected: [],
      currentCaseTemplate: {},
      selectable: true,
      formNewCase: {
        idOrganizationalUnit: null,
        caseTemplateName: null,
      },
      formState: {
        idOrganizationalUnit: null,
        caseTemplateName: null,
      },
      totalRows: 0,
    };
  },
  methods: {
    ...mapActions("Cases", [
      "getCaseTemplatesbyOU",
      "fetchOUs",
      "postNewCaseTemplates",
      "deleteCaseTemplates",
      "putCaseTemplatesEnabled",
    ]),
    EditCaseTemplate() {
      this.$router.push({
        path: `/case-templates/${this.selected[0].idCaseTemplate}`,
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    RemoveCaseTemplate() {
      this.busy = true;
      this.deleteCaseTemplates({
        api: this.$api,
        idCaseTemplate: this.selected[0].idCaseTemplate,
      })
        .then(() => {
          this.busy = false;
        })
        .catch((e) => {
          console.log(e);
          this.busy = false;
        });
    },
    ActiveCaseTemplate() {
      this.busy = true;
      this.putCaseTemplatesEnabled({
        api: this.$api,
        idCaseTemplate: this.selected[0].idCaseTemplate,
        idOrganizationalUnit: "",
        enabled: false,
      })
        .then(() => {
          this.busy = false;
        })
        .catch((e) => {
          console.log(e);
          this.busy = false;
        });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      const that = this;
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.busy = true;
      this.postNewCaseTemplates({ api: this.$api, ...this.formNewCase })
        .then(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide("modal-new-case");
          });
          this.formNewCase.idOrganizationalUnit = null;
          this.formState.idOrganizationalUnit = null;
          this.formNewCase.caseTemplateName = null;
          this.formState.caseTemplateName = null;
          this.busy = false;
        })
        .catch((err) => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-new-case");
          });
          this.busy = false;
          this.formNewCase.idOrganizationalUnit = null;
          this.formState.idOrganizationalUnit = null;
          this.formNewCase.caseTemplateName = null;
          this.formState.caseTemplateName = null;
          that.$bvToast.toast(err, {
            variant: "danger",
            title: that.$t("views.caseTemplateList.AddCaseTemplate"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    checkFormValidity() {
      this.formState.idOrganizationalUnit =
        this.formNewCase.idOrganizationalUnit === null ||
        this.formNewCase.idOrganizationalUnit === ""
          ? false
          : true;
      this.formState.caseTemplateName =
        this.formNewCase.caseTemplateName === null ||
        this.formNewCase.caseTemplateName === ""
          ? false
          : true;
      if (
        this.formNewCase.idOrganizationalUnit === null ||
        this.formNewCase.idOrganizationalUnit === "" ||
        this.formNewCase.caseTemplateName === null ||
        this.formNewCase.caseTemplateName === ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    handleCancel() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-new-case");
      });
      this.formNewCase.idOrganizationalUnit = null;
      this.formNewCase.caseTemplateName = null;
      this.formState.idOrganizationalUnit = null;
      this.formState.caseTemplateName = null;
    },
    filtered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    fetchData() {
      this.busy = true;
      this.getCaseTemplatesbyOU({
        api: this.$api,
        enabled: this.Enable,
      })
        .then(() => {
          this.busy = false;
          this.totalRows = this.caseTemplatesbyOU.length;
        })
        .catch((e) => {
          console.log(e);
          this.busy = false;
        });
    },
  },
  computed: {
    ...mapGetters("Cases", ["organizationalUnits", "caseTemplatesbyOU"]),
    options() {
      return this.organizationalUnits.organizationalUnits;
    },
    isSelected() {
      return this.selected.length < 1;
    },
    rows() {
      return this.caseTemplatesbyOU.length;
    },
    fields() {
      return [
        {
          key: "idCaseTemplate",
          sortable: true,
          label: this.$t("views.caseTemplateList.id"),
        },
        {
          key: "caseTemplateName",
          sortable: true,
          label: this.$t("views.caseTemplateList.name"),
        },
        {
          key: "organizationalUnitName",
          sortable: true,
          label: this.$t("views.caseTemplateList.OU"),
        },
      ];
    },
  },
  mounted() {
    this.fetchData();
    this.fetchOUs({
      session: this.$session,
      api: this.$api,
    });
  },
  watch: {
    Enable() {
      this.fetchData();
    },
  },
};
</script>
